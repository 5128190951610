<template>
  <div v-if="isModal" class="base-modal z99 flex-center wh100 fixed left0 top0">
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  const { state, dispatch, commit } = useStore();
  const isModal = computed(() => state.isModal);
</script>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.7);
    > div {
      width: 600px;
      padding: 24px;
      background-color: white;
      border-radius: 4px;
    }
  }
</style>
