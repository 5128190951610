import api from '@/store/api';
import axios from '../axios';
import router from '@/router';

const memberTemplate = function () {
  return {
    role: [],
    skills: [],
    projects: [{ name: '', role: '' }],
    gender: ''
  };
};

export const state = function () {
  return {
    fetching: false,
    uploading: false,
    members: [],
    membersCopy: [],
    member: memberTemplate(),
    sortBy: {
      available: false,
      role: '',
      contract: '',
      level: ''
    },
    totalMembers: 0,
    available: 0,
    projects: []
  };
};

export const mutations = {
  SET_DATA (state, { key, value }) {
    state[key] = value;
  },
  RESET_MEMBER (state) {
    state.member = memberTemplate();
  },
  SET_MEMBERS (state, data) {
    state.members = data;
    state.totalMembers = data.length;
    state.available = data.filter((o) => o.availability).length;
  },
  SET_MEMBER (state, data) {
    state.member = data;
  },
  ADD_MEMBER (state, member) {
    state.members.push(member);
  },
  UPDATE_MEMBER (state, id) {
    const idx = state.members.findIndex((o) => o._id === id);
    state.members[idx] = { ...state.member };
  },
  ADD_MEMBER_FIELD (state, { name, field, type, value }) {
    if (type === 'checkbox') {
      const idx = state.member[name].findIndex((item) => item === field);
      idx > -1
        ? state.member[name].splice(idx, 1)
        : state.member[name].push(field);
      return;
    }
    state.member[name] = field || value;
  },
  ADD_EMPTY_PROJECT (state) {
    state.member.projects.push({ name: '', role: '' });
  },
  DELETE_LAST_PROJECT (state) {
    state.member.projects.pop();
  },
  SELECT_PROJECT (state, { name, projects }) {
    const idx = state.member.projects.findIndex((o) => o.name === name);
    const index = projects.findIndex((o) => o.name === name);
    const props = ['_id', 'internal', 'not_started'];
    if (idx > -1) {
      props.forEach((value) => {
        state.member.projects[idx][value] = projects[index][value];
      });
    }
  },
  SEARCH_MEMBER (state, string) {
    if (!state.membersCopy.length) {
      state.membersCopy = state.members.slice();
    }
    state.members = state.membersCopy.filter(
      ({ name, projects }) =>
        name.toLowerCase().includes(string) ||
        projects.some((o) => o.name.toLowerCase().includes(string))
    );
    if (!string) {
      state.members = state.membersCopy.slice();
      state.membersCopy = [];
    }
  },
  SORT_BY (state, { name, value }) {
    state.sortBy[name] = value;
  }
};

export const actions = {
  async get_members ({ state, commit }) {
    try {
      const { data } = await axios(api.members);
      commit('SET_MEMBERS', data);
    } catch (e) {}
  },
  async get_projects ({ state, commit }) {
    try {
      const { data } = await axios(api.projects);
      commit('SET_DATA', { key: 'projects', value: data });
    } catch (e) {}
  },
  async get_member ({ state, commit }, id) {
    try {
      const { data } = await axios(`${api.members}/${id}`);
      commit('SET_MEMBER', data);
    } catch (err) {
      console.log(err);
    }
  },
  async save_member ({ state, commit }) {
    try {
      const { data } = await axios.post(api.members, state.member);
      commit('ADD_MEMBER', data);
      commit('RESET_MEMBER');
      await router.push('/members');
    } catch (e) {}
  },
  async update_member ({ state, commit }, id) {
    try {
      const { data } = await axios.put(`${api.members}/${id}`, state.member);
      console.log(data);
      commit('UPDATE_MEMBER', id);
      commit('RESET_MEMBER');
      await router.push('/members');
    } catch (e) {}
  }
};

export const getters = {
  sort_by_criteria (state) {
    let results = state.members.slice();
    if (state.sortBy.available) {
      results = results.filter(({ availability }) => availability > 0);
    }
    if (state.sortBy.internal) {
      results = results.filter(({ projects }) =>
        projects.some((o) => o.internal)
      );
    }
    if (state.sortBy.role) {
      results = results.filter(({ role }) => role.includes(state.sortBy.role));
    }
    if (state.sortBy.contract) {
      results = results.filter(({ contract_type }) =>
        contract_type.includes(state.sortBy.contract)
      );
    }
    if (state.sortBy.skill) {
      results = results.filter(({ skills }) =>
        skills.includes(state.sortBy.skill)
      );
    }
    if (state.sortBy.level) {
      results = results.filter(({ level }) => level === state.sortBy.level);
    }
    return results;
  }
};
