import axios from '@/store/axios';
import api from '@/store/api';

export const state = function () {
  return {
    settings: {
      projects: [{ name: '' }],
      skills: [],
      roles: [{}],
      levels: [],
      contracts: []
    }
  };
};

export const mutations = {
  DELETE_SETTINGS_ITEM (state, { name, index }) {
    state.settings[name].splice(index, 1);
  },
  SET_SETTINGS (state, data) {
    state.settings = data;
  },
  UPDATE_SETTINGS_DATA (state, { section, index, value }) {
    state.settings[section][index] = value;
  },
  ADD_NEW_SETTINGS_PROJECT (state, project) {
    state.settings.projects.push(project);
  },
  ADD_SETTINGS_DATA (state, { section, value }) {
    if (!state.settings[section]) {
      state.settings[section] = [];
    }
    state.settings[section].push(value);
  }
};

export const actions = {
  async get_settings ({ commit }) {
    try {
      const { data } = await axios(`${api.settings}`);
      commit('SET_SETTINGS', data);
    } catch (err) {
      console.log(err);
    }
  },
  async update_settings ({ state, commit }, name) {
    try {
      await axios.put(`${api.settings}/${name}`, state.settings[name]);
    } catch (e) {}
  }
};

export const getters = {
  get_setting: (state) => (name) => {
    return state.settings[name]?.map((o) => o.name) || [];
  }
};
