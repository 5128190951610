import axios from '@/store/axios';
import api from '@/store/api';

export const state = function () {
  return {
    data: {
      name: '',
      settings: {
        hide_wk: false,
        default_hours: '',
        start_from: '00'
      },
      timetable: [
        { date: 1, hours: 8 },
        { date: 4, hours: 8 },
        { date: 5, hours: 8 },
        { date: 6, hours: 'off' },
        { date: 17, hours: 0 }
      ],
      holidays: [{ id: 'abed', from: '09/27/2023', to: '10/4/2023' }]
    }
  };
};

export const mutations = {
  SET_USER (state, user) {
    state.data = user;
  },
  SET_USER_SETTING (state, { key, value }) {
    state.data.settings[key] = value;
  },
  SET_DAYS_OFF (state, { date, day_off }) {
    state.data.timetable?.forEach((o, index) => {
      state.data.timetable[index].hours =
        day_off && +o.date === +date ? 'off' : +o.hours;
    });
  },
  SET_TIMETABLE (state, data = []) {
    state.data.timetable = [...data];
  },
  UPDATE_DAY (state, data) {
    state.data.timetable.forEach((o, index) => {
      if (+o.date === +data.date) {
        state.data.timetable[index] = data;
      } else {
        delete data.year;
        delete data.month;
        state.data.timetable.push(data);
      }
    });
  },
  SET_HOLIDAYS (state, data) {
    const index = state.data.holidays.findIndex((o) => o.id === data.id);
    if (index > -1) {
      state.data.holidays[index] = { ...data };
    } else {
      state.data.holidays.unshift({ ...data });
    }
  },
  REMOVE_HOLIDAYS_BY_ID (state, id) {
    const index = state.data.holidays.findIndex(
      (o) => o.id.toString() === id.toString()
    );
    state.data.holidays.splice(index, 1);
  },
  SET_SETTINGS (state, value) {
    state.data.settings = value;
  }
};

export const actions = {
  async get_user_settings ({ commit }) {
    try {
      const { data } = await axios(`${api.users}/get-user`);
      commit('SET_USER', data);
    } catch (err) {
      console.log(err);
    }
  },
  async get_user_timetable ({ commit }, { year, month }) {
    try {
      const { data } = await axios(`${api.users}/timetable/${year}/${month}`);
      commit('SET_TIMETABLE', data);
    } catch (err) {
      console.log(err);
    }
  },
  async save_user_work_day ({ commit }, { section, value }) {
    try {
      console.log(value);
      await axios.put(`${api.users}/edit-user/${section}`, value);
      commit('SET_DAYS_OFF', value);
      commit('UPDATE_DAY', value);
    } catch (err) {
      console.log(err);
    }
  },
  async update_holidays ({ commit }, { value }) {
    try {
      value.from = new Date(value.from).getTime();
      value.to = new Date(value.to).getTime();
      const { data } = await axios.put(
        `${api.users}/edit-user/holidays`,
        value
      );
      commit('SET_HOLIDAYS', data);
    } catch (err) {
      console.log(err);
    }
  },
  async delete_holidays ({ commit }, { id }) {
    try {
      await axios.delete(`${api.users}/edit-user/holidays/${id}`);
      commit('REMOVE_HOLIDAYS_BY_ID', id);
      commit('SET_MODAL', { value: false });
    } catch (err) {
      console.log(err);
    }
  },
  async update_user_settings ({ state, commit }, value) {
    try {
      await axios.put(`${api.users}/edit-user/settings`, state.data.settings);
      commit('SAVE_SETTINGS', value);
    } catch (e) {}
  }
};
