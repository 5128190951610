import axios from '@/store/axios';
import api from '@/store/api';

export const state = function () {
  return {
    data: [],
    project: {}
  };
};

export const mutations = {
  SAVE_PROJECT (state, project) {
    state.data.push(project);
  },
  UPDATE_PROJECT (state) {
    const index = state.data.findIndex((o) => o._id === state.project._id);
    state.data[index] = { ...state.project };
  },
  RESET_PROJECT (state, project) {
    state.project = project || {};
  }
};

export const actions = {
  async save_new_project ({ commit }, { project }) {
    try {
      const { data } = await axios.post(api.projects, project);
      commit('SAVE_PROJECT', data);
      commit('ADD_NEW_SETTINGS_PROJECT', data);
      commit('RESET_PROJECT');
    } catch (e) {
      console.log(e);
    }
  },
  async update_project ({ commit }, { _id, project }) {
    try {
      await axios.put(`${api.projects}/${_id}`, project);
      commit('UPDATE_PROJECT');
      commit('RESET_PROJECT');
    } catch (e) {}
  }
};
