<template>
  <label class="base-checkbox pointer" :cy="`${field || name}-checkbox`">
    <input
      :name="name"
      :type="type || 'checkbox'"
      class="pointer"
      :checked="checked"
      @change="handleChange"
    />
    <span>{{ label || field }}</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    name: String,
    type: String,
    field: String,
    label: String,
    checked: Boolean,
    validate: Boolean,
    rule: String
  });

  const emits = defineEmits(['change']);

  function handleChange (ev) {
    emits('change', {
      name: props.name,
      field: props.field || props.label,
      checked: ev.target.checked
    });
  }
</script>

<style lang="scss">
  .base-checkbox {
    font-size: 1.6rem;
    input {
      margin-right: 10px;
      transform: scale(1.3);
    }
  }
</style>
