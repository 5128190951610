<template>
  <div class="base-input" :cy="name">
    <label :for="name"
      ><b>{{ label || name }}</b
      ><b v-if="rule === 'required'">*</b></label
    >
    <input
      :id="name"
      :type="type || 'text'"
      :value="value"
      @input="handleInput"
      :placeholder="placeholder || label || name"
    />
    <span v-if="validate" class="validation-msg" :cy="`${name}-required`">{{
      validationMsgs[rule]
    }}</span>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    type: String,
    index: Number,
    placeholder: String,
    value: String,
    validate: Boolean,
    rule: String
  });
  const validationMsgs = {
    required: 'This field is required'
  };
  const emits = defineEmits(['input']);

  function handleInput (ev) {
    emits('input', {
      name: props.name,
      value: ev.target.value,
      index: props.index
    });
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-input {
    @media only screen and (min-width: 0) {
      label {
        margin-bottom: 6px;
        display: block;
        font-size: 1.6rem;
      }
      input,
      textarea {
        width: 100%;
        padding: 8px 0 8px 12px;
        font-size: 1.6rem;
        border: 1px solid $input-border-color;
        border-radius: 4px;
      }
      textarea {
        width: 100%;
        min-height: 80px;
      }
    }
  }
</style>
