import { createStore } from 'vuex';
import * as auth from './modules/auth';
import * as settings from './modules/settings';
import * as member from './modules/member';
import * as users from './modules/users';
import * as user from './modules/user';
import * as projects from './modules/projects';

export default createStore({
  state: {
    isModal: false,
    componentName: '',
    confirmation: {
      message: '',
      actions: false,
      confirmFn: function () {},
      cancelFn: function () {},
      closeIcon: false
    }
  },
  getters: {},
  mutations: {
    SET_MODAL (state, { component, value, payload }) {
      state.componentName = component;
      state.isModal = value;
      state.confirmation = { ...state.confirmation, ...payload };
    }
  },
  actions: {},
  modules: {
    auth,
    settings,
    member,
    users,
    user,
    projects
  }
});
