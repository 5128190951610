import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BaseInput from '@/components/BaseInput';
import BaseCheckBoxGroup from '@/components/BaseCheckBoxGroup';
import BaseCheckBox from '@/components/BaseCheckBox';
import BaseSearchBar from '@/components/BaseSearchBar';
import BaseModal from '@/components/BaseModal';
import BaseConfirmation from '@/components/BaseConfirmation';
import VueMultiselect from 'vue-multiselect';
import Datepicker from '@vuepic/vue-datepicker';
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/grid.scss';
import './styles/base.scss';
import 'vue-multiselect/dist/vue-multiselect.css';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Lottie from 'vue3-lottie';

const app = createApp(App);

app.use(Vue3Lottie);
app.component('BaseInput', BaseInput);
app.component('BaseCheckBoxGroup', BaseCheckBoxGroup);
app.component('BaseCheckBox', BaseCheckBox);
app.component('BaseSearchBar', BaseSearchBar);
app.component('BaseConfirmation', BaseConfirmation);
app.component('BaseModal', BaseModal);
app.component('VueMultiselect', VueMultiselect);
app.component('Datepicker', Datepicker);

app.use(store).use(router).mount('#app');
