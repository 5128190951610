<template>
  <BaseModal>
    <BaseConfirmation v-show="componentName === 'confirmation'" />
  </BaseModal>
  <router-view />
</template>

<script setup>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  const { state, dispatch, commit } = useStore();
  const componentName = computed(() => state.componentName);
</script>

<style lang="scss"></style>
