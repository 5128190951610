<template>
  <div class="base-checkbox-group" :cy="name">
    <b class="block m-b-8"
      >{{ label || name }} <span v-if="rule === 'required'">*</span></b
    >
    <div class="flex flex-wrap">
      <span
        class="inline-block m-b-8"
        v-for="(item, index) in options"
        :key="index"
      >
        <BaseCheckBox
          @change="handleChange"
          :type="type"
          :name="name"
          :checked="
            Array.isArray(value)
              ? value.includes(item.name)
              : item.label === value
          "
          :field="item.name"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    rule: String,
    type: String,
    label: String,
    value: [String, Array], // ['UX', 'UI']
    options: Array // [{name: 'FE'}]
  });

  const emits = defineEmits(['change']);

  function handleChange ({ name, field, checked }) {
    emits('change', { name, field, type: props.type, value: checked });
  }
</script>

<style lang="scss">
  .base-checkbox-group {
    font-size: 1.6rem;
  }
</style>
