import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "about" */ '../layouts/MainLayout.vue'),
    children: [
      { path: '', redirect: '/members' },
      {
        path: '/members',
        name: 'members',
        meta: { auth: true, roles: ['admin', 'manager'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Members.vue')
      },
      {
        path: '/add-member',
        name: 'add-member',
        meta: { auth: true, roles: ['admin', 'manager'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/AddMember.vue')
      },
      {
        path: '/edit-member/:id',
        name: 'edit-member',
        meta: { auth: true, roles: ['admin', 'manager'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/AddMember.vue')
      },
      {
        path: '/projects',
        name: 'projects',
        meta: { auth: true, roles: ['admin', 'manager'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Projects.vue')
      },
      {
        path: '/clients',
        name: 'clients',
        meta: { auth: true, roles: ['admin'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Clients.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        meta: { auth: true, roles: ['admin'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Settings.vue')
      },
      {
        path: '/users',
        name: 'users',
        meta: { auth: true, roles: ['admin'] },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Users.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/clocking',
    name: 'clocking',
    meta: { auth: true, roles: ['member'] },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Clocking.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes
});

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', { roles: meta.roles, next })
    : next();
  // next();
});

export default router;
