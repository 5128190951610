import axios from 'axios';
import api from '../api';
import router from '@/router';

export const state = {
  user: { role: '' },
  wrongCredentials: false
};

export const mutations = {
  SET_USER (state, user) {
    state.user = user;
    state.wrongCredentials = false;
  },
  SET_AUTH_STATE (state, { key, value }) {
    state[key] = value;
  }
};

export const actions = {
  async login ({ state, commit }, user) {
    try {
      const { data } = await axios.post(api.login, user);
      commit('SET_AUTH_STATE', { key: 'credentials', value: {} });
      commit('SET_USER', data);
      await router.push(data.role === 'member' ? '/clocking' : '/members');
    } catch (err) {
      commit('SET_AUTH_STATE', { key: 'wrongCredentials', value: true });
      console.log(err);
    }
  },
  async logout ({ commit }) {
    try {
      await axios.delete(api.logout);
      commit('SET_USER', {});
      await router.push('/login');
    } catch (err) {
      console.log(err);
    }
  },
  async get_user ({ commit, dispatch }, { roles, next = () => {} }) {
    try {
      const { data: user } = await axios.get(api.checkLogin);
      if (user.name) {
        commit('SET_USER', user);
        roles.includes(user.role)
          ? next()
          : user.role === 'member'
          ? next('/clocking')
          : next('/login');
      }
    } catch (e) {
      next('/login');
    }
  },
  async signup ({ commit }, user) {
    try {
      const { data } = await axios.post(api.signup, user);
      commit('SET_AUTH_STATE', { key: 'accountCreated', value: true });
    } catch (err) {
      console.log(err);
    }
  },
  async delete_account ({ commit }, user) {
    try {
      const { data } = await axios.delete(api.delete_account);
      commit('SET_USER', {});
    } catch (err) {
      console.log(err);
    }
  }
};
