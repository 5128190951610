import axios from 'axios';
import store from './index'; // Import your Vuex store instance

const axiosInstance = axios.create({
  // baseURL: 'your_api_base_url',
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const key =
      config.method.toLowerCase() === 'get' ? 'fetching' : 'uploading';
    store.commit('SET_DATA', { key, value: true });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Commit a mutation after a successful response is received
    store.commit('SET_DATA', { key: 'fetching', value: false });
    store.commit('SET_DATA', { key: 'uploading', value: false });
    return response;
  },
  (error) => {
    // Commit a mutation in case of an error
    store.commit('SET_DATA', { key: 'fetching', value: false });
    store.commit('SET_DATA', { key: 'uploading', value: false });
    return Promise.reject(error);
  }
);

export default axiosInstance;
