import axios from '@/store/axios';
import api from '@/store/api';

export const state = function () {
  return {
    company: {
      name: 'TS'
    },
    users: []
  };
};

export const mutations = {
  SET_USERS (state, users) {
    state.users = users;
  }
};

export const actions = {
  async get_users ({ commit }) {
    try {
      const { data } = await axios(`${api.users}`);
      commit('SET_USERS', data);
    } catch (err) {
      console.log(err);
    }
  }
};
