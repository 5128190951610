<template>
  <div class="base-confirmation relative" cy="confirmation-modal">
    <i v-if="closeIcon" @click="handleClose" class="close-icon absolute">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="14" r="14" fill="white" />
        <path
          d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
          fill="black"
        />
      </svg>
    </i>
    <p class="text-center" v-html="message" />
    <div v-if="actions" class="actions m-t-16 flex-center">
      <button
        class="base-btn m-r-16"
        cy="confirmation-yes"
        @click="handleAction('yes')"
      >
        YES
      </button>
      <button class="base-btn" cy="confirmation-no" @click="handleAction('no')">
        NO
      </button>
    </div>
    <button v-else @click="handleClose">OK</button>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  const { state, dispatch, commit } = useStore();
  const message = computed(() => state.confirmation.message);
  const actions = computed(() => state.confirmation.actions);
  const confirm = computed(() => state.confirmation.confirmFn);
  const cancel = computed(() => state.confirmation.cancelFn);
  const closeIcon = computed(() => state.confirmation.closeIcon);

  function handleAction (action) {
    if (action === 'yes') {
      confirm.value();
    }
    if (action === 'no') {
      cancel.value();
      handleClose();
    }
  }

  function handleClose () {
    commit('SET_MODAL', { value: false });
  }
</script>

<style lang="scss">
  .base-confirmation {
    padding: 16px;
    p {
      font-size: 2rem;
      line-height: 1.6;
    }
    .close-icon {
      top: -41px;
      right: -39px;
    }
  }
</style>
