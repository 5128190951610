export default {
  login: '/api/auth/login',
  delete_account: '/api/auth/user',
  signup: '/api/auth/signup',
  logout: '/api/auth/logout',
  checkLogin: '/api/auth/login',
  members: '/api/members',
  settings: '/api/settings',
  projects: '/api/projects',
  users: '/api/users'
};
